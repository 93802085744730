// src/utils/localDB.js or public/localDB.js version 1.2
const dbName = 'AuthDB';
const storeName = 'keyval';

async function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);
    request.onupgradeneeded = event => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    };
    request.onsuccess = event => resolve(event.target.result);
    request.onerror = event => reject(event.target.errorCode);
  });
}

async function setItem(key, value) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.put(value, key);
    request.onsuccess = () => resolve();
    request.onerror = event => reject(event.target.errorCode);
  });
}

async function getItem(key) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName]);
    const store = transaction.objectStore(storeName);
    const request = store.get(key);
    request.onsuccess = event => resolve(event.target.result);
    request.onerror = event => reject(event.target.errorCode);
  });
}

async function removeItem(key) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.delete(key);
    request.onsuccess = () => resolve();
    request.onerror = event => reject(event.target.errorCode);
  });
}

// Creëer het localDB object
const localDB = {
  setItem,
  getItem,
  removeItem
};

// Export voor gebruik in ES6-modules (Vue-app)
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = localDB;
}

// Maak beschikbaar voor gebruik in Service Workers via importScripts
if (typeof self !== 'undefined') {
  self.localDB = localDB;
}
