<template>
  <div class="container">
    <label for="exampleInputEmail1" class="form-label">Filter</label>
    <div class="form-check form-switch mt-1">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status.verwerkt" id="flexCheckDefault1" key="" >
      <label class="form-check-label" for="flexCheckDefault">
        <i class="bi bi-circle-fill close" :class="getColor('automatisch verwerkt')" ></i> Aut. verwerkt
      </label>
    </div>
    <div class="form-check form-switch">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status.failed" id="flexCheckChecked2" >
      <label class="form-check-label" for="flexCheckChecked">
        <i class="bi bi-circle-fill close" :class="getColor('failed')" ></i> Failed
      </label>
    </div>
    <div class="form-check form-switch">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status.dubbel" id="flexCheckChecked3" >
      <label class="form-check-label" for="flexCheckChecked">
        <i class="bi bi-circle-fill close" :class="getColor('dubbel orderaanbod')" ></i> Dubbel
      </label>
    </div>
    <div class="form-check form-switch">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status.unit4" id="flexCheckChecked4" >
      <label class="form-check-label" for="flexCheckChecked">
        <i class="bi bi-circle-fill close" :class="getColor('verstuurd naar unit4')" ></i> Naar Unit4
      </label>
    </div>
    <div class="form-check form-switch">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status.open" id="flexCheckChecked5" >
      <label class="form-check-label" for="flexCheckChecked">
        <i class="bi bi-circle-fill close" :class="getColor('open')" ></i> Open
      </label>
    </div>
    <div class="dropdown mt-4">
      <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Selecteer klanten</button>
      <div class="dropdown-menu px-2">
        <form class="px-4 py-3">
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.sabic" id="dropdownCheck1">
              <label class="form-check-label" for="dropdownCheck">Sabic</label>
            </div>
              <div class="form-check mb-2">
                <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.tata" id="dropdownCheck2">
                <label class="form-check-label" for="dropdownCheck">Tata</label>
              </div>
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.esso" id="dropdownCheck3">
              <label class="form-check-label" for="dropdownCheck">Esso</label>
            </div>
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.huntsman" id="dropdownCheck4">
              <label class="form-check-label" for="dropdownCheck">Huntsman</label>
            </div>
            <div class="form-check mb-2">
              <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer.cabot" id="dropdownCheck5">
              <label class="form-check-label" for="dropdownCheck">Cabot</label>
            </div>
        </form>
      </div>
    </div>
    <div>
      <p class="fs-6 text-start text-secondary"><i>{{ selectedCustomer }}</i></p>
    </div>
  </div>
</template>

<script>
import Utils from "../utils/Utils"
import { watch } from 'vue'
import ServiceFactory from '@/services/ServiceFactory'
var capitalize = require('lodash/capitalize');
import localDB from '../utils/localDB';
export default {
  name: 'FilterOrderBevestiging',
  data() {
    return {
      filter: {
        status: {
          verwerkt:false, failed:false, dubbel:false, unit4: false, open:false
        },
        customer: {
          sabic:false, tata:false, esso:false, huntsman:false, cabot:false
        }
      },
      selectedCustomer: "Geen selectie",
    }
  },
  emits: ['filterStatusChanged'],
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status)
    },
    filterStatusChanged() {
      this.updateSelectedCustomerString()
      this.$emit('filterStatusChanged', this.filter)
    },
    updateSelectedCustomerString(){
      this.selectedCustomer = ""
      for(let customer in this.filter.customer) {
        let customerSelected = this.filter.customer[customer]
        if(customerSelected){
          this.selectedCustomer += `${capitalize(customer)}, `
        }
      }
      if(this.selectedCustomer.length > 0) {
        this.selectedCustomer = this.selectedCustomer.slice(0, -2)
      }else{
        this.selectedCustomer = "Geen selectie"
      }
    },
  },
  async mounted() {
    this.orderService = ServiceFactory.getService('OrderBevestigingService')
    this.orderService.addEventListener('orderBevestigingenLoaded', () => {
      this.filterStatusChanged()
    });
    
    const storedFilter = await localDB.getItem('orderBevestigingFilter');
    if (storedFilter) {
      this.filter = JSON.parse(storedFilter);
      this.updateSelectedCustomerString()
    }
    
    watch(() => this.filter, async (newValue) => {
      await localDB.setItem('orderBevestigingFilter', JSON.stringify(newValue));
    }, { deep: true });
  }
  
}
</script>