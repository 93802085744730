<template>
  <div class="container main border rounded p-2">
    <div class="d-flex align-items-center">
      <h5 class="mb-0">Dev Tools</h5>
      <button class="btn btn-sm custom-purple-btn ms-2" @click="invalidateToken">Invalidate token</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevTools',
  methods: {
    invalidateToken() {
      this.$emit('invalidate-token');
    }
  }
}
</script>

<style scoped>
.custom-purple-btn {
  background-color: purple;
  color: white;
}
.custom-purple-btn:hover {
  background-color: darkpurple;
}
</style>