import CustomEventDispatcher from './CustomEventDispatcher';

class WebSocketService extends CustomEventDispatcher {
  constructor() {
    super();
    this.socket = null;
  }

  connect() {
    const url = this.rootUrl().replace('http', 'ws');
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.socket = new WebSocket(url);
    }

    this.socket.onopen = () => {
      console.log('WebSocket is open now.');
    };

    this.socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      const customEvent = new CustomEvent(response.eventType, { detail: response.data });
      setTimeout(() => {
        this.dispatchEvent(customEvent);
      }, 100);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error: ', error);
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket is closed now. Trying to reconnect', event);
      setTimeout(() => {
        this.connect();
      }, 1000);
    };
  }

  send(path, data) {
    this.socket.send(JSON.stringify({ path, data }));
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  rootUrl() {
    if (window.location.origin.indexOf('localhost') === -1) {
      return '';
    } else {
      return "ws://localhost:3000";
    }
  }
}

export default WebSocketService;