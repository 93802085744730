class Utils {

  static getColorForOrderStatus(status) {
    if (!status) return '';
    if (Object.prototype.hasOwnProperty.call(status, 'id')) {
      status = status.id;
    }
    switch (status) {
      case 1: // automatisch verwerkt
        return 'text-success';
      case 2: // mislukt
        return 'text-danger';
      case 3: // open
        return 'text-black';
      case 4: // dubbel orderaanbod
        return 'text-warning';
      case 5: // onjuist orderbedrag
        return 'text-warning';
      case 6: // handmatig verwerkt
        return 'text-success';
      case 7: // order zonder regels
        return 'text-danger';
      case 8: // verstuurd naar Unit4
        return 'text-purple';
    }
    return 'text-gray'
  }

  static getColorForInvoiceStatus(status) {
    if (!status) return '';
    if (Object.prototype.hasOwnProperty.call(status, 'id')) {
      status = status.id;
    }
    switch (status) {
      case 1: // automatisch verwerkt
        return 'text-success';
      case 2: // mislukt
        return 'text-danger';
      case 3: // handmatig verwerkt
        return 'text-success';
      case 4: // geannuleerd
        return 'text-warning';
      case 5: // Verwerk handmatig
        return 'text-purple';
    }
    return 'text-gray'
  }

  static formatDateTimeString(isoString) {
    if (!isoString || isoString === '') return '';

    const date = new Date(isoString);
    const now = new Date();

    const isSameDay =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    const isSameYear = date.getFullYear() === now.getFullYear();

    let formattedDate;
    if (isSameDay) {
      // Als het dezelfde dag is, geef de tijd terug in 24-uurs formaat
      formattedDate = date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit', hour12: false });
    } else if (isSameYear) {
      // Als het hetzelfde jaar is, geef de datum terug zonder jaar
      formattedDate = date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short' });
    } else {
      // Als het een ander jaar is, geef de datum terug met jaar
      formattedDate = date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric' });
    }
    // Controleer of de maand een punt bevat; voeg anders een punt toe
    formattedDate = formattedDate.replace(/\b(\w{3})(?!\.)\b/g, '$1.');
    return formattedDate;
  }

  static getCustomFormattedDate(
    isoString,
    options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    hour12 = false
  ) {

    options.hour12 = hour12;
    if (!isoString || isoString == '') return ''
    const date = new Date(isoString);

    const formatter = new Intl.DateTimeFormat('nl-NL', options);
    const formattedDate = formatter.format(date);

    // Vervang slashes met dashes
    return formattedDate.replace(/\//g, '-');
  }


  static formatDateString(isoString) {
    if (!isoString || isoString === '') return '';

    const date = new Date(isoString);
    const now = new Date();

    const isSameYear = date.getFullYear() === now.getFullYear();

    let formattedDate;
    if (isSameYear) {
      // Als het hetzelfde jaar is, geef de datum terug zonder jaar
      formattedDate = date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short' });
    } else {
      // Als het een ander jaar is, geef de datum terug met jaar
      formattedDate = date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric' });
    }
    // Controleer of de maand een punt bevat; voeg anders een punt toe
    formattedDate = formattedDate.replace(/\b(\w{3})(?!\.)\b/g, '$1.');
    return formattedDate;
  }

  static formatCurrency(amount, currency = 'EUR', showCurrency = false) {
    // Gebruik de eerste voorkeurstaal van de gebruiker (meestal de browsertaal)
    const locale = navigator.languages ? navigator.languages[0] : navigator.language;

    const options = {
      style: showCurrency ? 'currency' : 'decimal',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };

    return new Intl.NumberFormat(locale, options).format(amount);
  }
}

export default Utils;