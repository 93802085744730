<template>
  <div class="container">
    <label for="invoiceStatussen" class="form-label mb-1">Filter</label>
    <div v-for="status in invoiceStatussen" :key="status.id" class="form-check form-switch">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status[`status_${status.id}`]" :id="`status_${status.id}`">
      <label class="form-check-label label-text" :for="`status_${status.id}`">
        &nbsp;<i class="bi bi-circle-fill close" :class="getColor(status.id)"></i>&nbsp;{{ status.label }}
      </label>
    </div>
    <div class="dropdown mt-4">
      <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Selecteer klanten</button>
      <div class="dropdown-menu px-2">
        <form class="px-4 py-3">
          <div class="form-check mb-2" v-for="(customer, key) in customerOptions" :key="key">
            <input type="checkbox" @change="filterStatusChanged()" class="form-check-input" v-model="filter.customer[key]" :id="`dropdownCheck_${key}`">
            <label class="form-check-label" :for="`dropdownCheck_${key}`">{{ customer }}</label>
          </div>
        </form>
      </div>
    </div>
    <div>
      <p class="fs-6 text-start text-secondary"><i>{{ selectedCustomer }}</i></p>
    </div>
  </div>
</template>

<script>
import Utils from "../utils/Utils"
import { watch } from 'vue'
import ServiceFactory from '@/services/ServiceFactory'
var capitalize = require('lodash/capitalize')
import localDB from '../utils/localDB';

export default {
  name: 'FilterInvoice',
  props: {
    invoiceStatussen: Array 
  },
  emits: ['filterStatusChanged'],
  data() {
    return {
      invoiceService: null,
      filter: {
        status: {},
        customer: {},
        userAgentFilter: ''
      },
      customerOptions: {
        sabic: 'Sabic',
        tata: 'Tata',
        esso: 'Esso',
        huntsman: 'Huntsman',
        cabot: 'Cabot',
        weir: 'Weir',
      },
      selectedCustomer: "Geen selectie",
      userAgentFilter: ''
    }
  },
  watch: {
    invoiceStatussen: {
      handler: function (val) {
        if (val) {
          val.forEach(status => {
            this.filter.status[`status_${status.id}`] = false;
          });
        }
      },
      deep: true
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForInvoiceStatus(status)
    },
    filterStatusChanged() {
      this.updateSelectedCustomerString()
      this.$emit('filterStatusChanged', this.filter)
    },
    updateSelectedCustomerString() {
      this.selectedCustomer = ""
      for (let customer in this.filter.customer) {
        let customerSelected = this.filter.customer[customer]
        if (customerSelected) {
          this.selectedCustomer += `${capitalize(customer)}, `
        }
      }
      if (this.selectedCustomer.length > 0) {
        this.selectedCustomer = this.selectedCustomer.slice(0, -2)
      } else {
        this.selectedCustomer = "Geen selectie"
      }
    }
  },
  async mounted() {
    this.invoiceService = ServiceFactory.getService('InvoiceService')
    this.invoiceService.addEventListener('invoicesLoaded', () => {
      this.filterStatusChanged()
    })

    const storedFilter = await localDB.getItem('invoiceFilter')
    if (storedFilter) {
      this.filter = JSON.parse(storedFilter)
      this.updateSelectedCustomerString()
    }

    watch(() => this.filter, async (newValue) => {
      await localDB.setItem('invoiceFilter', JSON.stringify(newValue))
    }, { deep: true })
  }
}
</script>

<style scoped>
.form-check-input {
  position: relative;
  top: -2px;
}
.form-check {
  display: flex;
  align-items: center;
}
.label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;  
  display: inline-block;
}
.rounded-input {
  border-radius: 8px; 
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
</style>