import axios from 'axios'

class OAuth2AzureService {

  credentials = {
    tenant: "ce777e5f-ed14-4139-b7a4-5d21ef3e6dea",
    app_id: "9e625485-e41b-4c2a-8e50-a1c46d4a75e4",
    redirect_uri: `${this.rootUrl(true)}/orders`,
    scope: "api://9e625485-e41b-4c2a-8e50-a1c46d4a75e4/Accounts.Lezen",
    state: "visjagertrompetbloemkool"
  }

  url(credentials) {
    return `https://login.microsoftonline.com/${credentials.tenant}/oauth2/v2.0/authorize?
      client_id=${credentials.app_id}&
      response_type=code&
      redirect_uri=${credentials.redirect_uri}&
      scope=${credentials.scope}&
      response_mode=query&
      state=${credentials.state}&
      prompt=login`
  }

  rootUrl(redirect = false) {
    const { hostname, protocol, port } = window.location;
    if (hostname === 'localhost') {
      if (!redirect) {
        return `${protocol}//${hostname}:${port}`;
      } else {
        return `${protocol}//${hostname}:3000`;
      }
    } else {
      return `${protocol}//${hostname}`;
    }

  }

  redirectToAzureLogin() {
    window.location.href = this.url(this.credentials);
  }

  async sendAuthCodeToServer(code) {
    try {
      const response = await axios.post(this.rootUrl() + '/api/authorize', { code: code })
      console.log(`oAuth2AzureService=>sendAuthCodeToServer(): `, response.data)
      if (response.data.success) {
        return response.data
      } else {
        console.warn(`oAuth2AzureService=>sendAuthCodeToServer(): No success in response data`, response.data);
        return null
      }
    } catch (error) {
      console.log(`oAuth2AzureService=>sendAuthCodeToServer(): `, error.response.data.message, error)
    }
  }

  async refreshAccessToken(refreshToken) {
    try {
      const response = await axios.post(this.rootUrl() + '/api/refresh', { refresh_token: refreshToken })

      if (response.data.success) {
        console.log(`oAuth2AzureService=>refreshAccessToken(): `, response.data)
        return response.data
      } else {
        return null
      }
    } catch (error) {
      console.log(`oAuth2AzureService=>refreshAccessToken(): `, error.response.data.message, error)
    }
  }

}
export default OAuth2AzureService;