import axios from 'axios';
import CustomEventDispatcher from './CustomEventDispatcher'

class BettyBlocksOrderBevestigingService extends CustomEventDispatcher {
  
  constructor() {
    super()
    this.response = {};
  }

  rootUrl() {
    if (window.location.origin.indexOf('localhost') === -1) {
      return '';
    } else {
      return "http://localhost:8000";
    }
  }

  async getOrderBevestigingen() {
    const url = 'https://orderplatform-develop.betty.app/api/runtime/940eebdfe3d546c89dc3cfcce57a6cb8';
    const body = {
      "operationName":'getOrderBevestigingen',
      "variables":{
        "input":{
          "graphql":"query{allOrderBevestiging{totalCount results {id status reference voNumber customer {name}}}}"
        }
      },
      "query":"mutation {\n  action(id: \"80e601631ebb4b80b4f645061f303b3e\", input: $input)\n}\n"
    };

    try {
      const response = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const orders = this.normalizeGetOrderBevestigingResponse(response.data.data.action.results)
      const event = new CustomEvent('orderBevestigingenLoaded', { detail: orders });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);
      return orders
    } catch (error) {
      console.error('Er is een fout opgetreden:', error);
    }
  }


  normalizeGetOrderBevestigingResponse(json){
    let nJsonOrderResponse = []
    for(let i in json) {
      const order = json[i]
      let nOrder = order
      if(order.customer){
        nOrder.customer = order.customer.name
      }
      delete order.order_customer
      nJsonOrderResponse.push(order)
    }
    return nJsonOrderResponse
  }
}

export default BettyBlocksOrderBevestigingService;
