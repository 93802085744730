<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="input-wrapper" @click="enableOrderLineInput" :class="{ 'input-disabled': disabled }">
      <input
        :type="type"
        class="form-control"
        :class="classString"
        :disabled="disabled"
        :style="styleString"
        @change="changed = true"
        @blur="disableInput"
        @keyup.enter="blurInput"
        :ref="`orderlineInputField-${rowIndex}`"
        v-model="value"
      >
      <button v-if="disabled" class="enable-input-btn"></button>
      <button v-if="!disabled" @mousedown.stop="prepareToClear" @mouseup.stop="clearInput" class="clear-input-btn">
        <i class="bi bi-x"></i>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EditInvoiceProp',
  props: ['property', 'classStr', 'styleStr', 'round', 'type', 'rowIndex'],
  emits: ['changed'],
  data() {
    return {
      orderlineProp: {},
      changed: false,
      classString: '',
      styleString: '',
      value: null,
      disabled: true,
      clearInProgress: false,
    }
  },
  watch: {
    property() {
      this.init();
    }
  },
  methods: {
    enableOrderLineInput() {
      console.log(`enableOrderLineInput() orderlineInputField-${this.rowIndex}`)
      this.disabled = false;
      this.$nextTick(() => {
        this.$refs[`orderlineInputField-${this.rowIndex}`].focus();
      });
    },
    disableInput() {
      if (!this.clearInProgress) { 
        this.disabled = true;
        this.saveOrderlineProp();
      }
    },
    async saveOrderlineProp() {
      if (this.changed) {
        this.orderlineProp = this.value
        this.$emit('changed', this.orderlineProp)
      }
    },
    blurInput() {
      this.$refs[`orderlineInputField-${this.rowIndex}`].blur();
    },
    prepareToClear() {
      this.clearInProgress = true;
    },
    clearInput() {
      this.value = '';
      this.clearInProgress = false;
    },
    init() {
      this.orderlineProp = this.property
      if (this.round !== undefined) {
        let val = this.property
        if (typeof val !== 'number') {
          val = Number(val)
        }
        this.value = val.toFixed(this.round)
      } else {
        this.value = this.property
      }
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>
.enable-input-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.input-disabled input {
  cursor: pointer;
}

.clear-input-btn {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f8f9fa;
}

.clear-input-btn:hover {
  background-color: #e0e0e0;
}

.clear-input-btn i {
  font-size: 1em;
  color: #6c757d;
}
</style>