class CustomEventDispatcher {

  constructor() {
    this.eventTarget = new EventTarget();
  }

  dispatchEvent(event) {
    this.eventTarget.dispatchEvent(event);
  }

  addEventListener(type, callback) {
    this.eventTarget.addEventListener(type, callback);
  }

  removeEventListener(type, callback) {
    this.eventTarget.removeEventListener(type, callback);
  }

  dispatchRedirectToLogin() {
    const event = new CustomEvent('redirectToLoginPage');
    this.dispatchEvent(event);
  }

  dispatchUnauthorized() {
    const event = new CustomEvent('unauthorized');
    this.dispatchEvent(event);
  }

  dispatchCustomEvent(eventName, detail) {
    const event = new CustomEvent(eventName, { detail: detail });
    this.dispatchEvent(event);
  }
}

export default CustomEventDispatcher