<template>
  <div class="editable-xml-container">
    <!-- <button @click="tryChanges" v-if="editable" class="btn btn-primary btn-sm float-end mb-2">Probeer wijzigingen</button> -->
    <textarea v-model="xmlContent" :disabled="!editable" class="form-control" rows="25"></textarea>
  </div>
</template>

<script>

export default {
  name: 'EditableXmlView',
  props: ['initialXml', 'editable'],
  emits: ['updateXml'],
  data() {
    return {
      xmlContent: this.initialXml,
    }
  },
  methods: {
    async tryChanges() {
      this.$emit('updateXml', this.xmlContent)
    }
  }
}
</script>

<style scoped>
.editable-xml-container {
  position: relative;
}
</style>