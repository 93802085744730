<template>
  <div class="d-flex justify-content-center align-items-center mt-5 ">
    <div class="card" style="width: 30rem;">
      <div class="card-body">
        <h5 class="card-title">Login</h5>
        <form @submit.prevent="handleSubmit">
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceFactory from '../services/ServiceFactory'

export default {
  name: 'LoginPage',
  props: ['formId'],
  data() {
    return {
      email: "",
      password: "",
      service: {},
      alertVisible: false,
      alertMessage: "",
    }
  },
  methods: {
    async handleSubmit() {
      this.service = ServiceFactory.getService('OAuth2AzureService')
      this.alertVisible = false
      this.service.redirectToAzureLogin()
    }
  },
  created() {
    //this.service = ComponentFactory.getService('formAdmin')
  }

}
</script>