<template>
  <nav class="navbar navbar-expand-lg navbar-white bg-white text-primary fixed-top shadow">
    <div class="container main d-flex justify-content-between">
      <router-link class="nav-link" to="/orders"><img src="/../img/klinger_logo.svg" class="img-fluid" style="height:3rem;" /></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item"><a class="nav-link" href="https://www.klinger.nl/" target="blank">Klinger.nl</a></li>
              <li class="nav-item"><a class="nav-link" href="https://www.klinger.nl/producten" target="blank">Shop</a></li>
              <li class="nav-item"><a class="nav-link" href="https://openai-backend-8nss.onrender.com/" target="blank">Chat</a></li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button"
                      data-bs-toggle="dropdown" aria-expanded="false">Account</a>
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item" href="#" @click="goToPage('/logViewer')">Logs</a></li>
                      <li><a class="dropdown-item" href="#">Contact</a></li>
                      <li>
                          <hr class="dropdown-divider" />
                      </li>
                      <li><a class="dropdown-item" @click="logoff" href="#">Logoff</a></li>
                  </ul>
              </li>
          </ul> 
      </div> 
    </div>
    <span class="ms-2 mt-5 me-2 version-number">v0.1.4</span>
  </nav>
</template>

<script>

export default {
  name: 'NavBar',
  props: ['formId'],
  methods: {
    logoff() {
      this.$emit('logoff')
    },
    goToPage(page) {

      this.$router.push(page)
    }

  }
}
</script>

<style scoped>
.version-number {
  font-size: 10px;       /* Maak de tekst klein */
  color: rgba(0, 0, 0, 0.1); /* Maak de tekst bijna transparant */
}
</style>
