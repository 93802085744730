<template>
  <div class="card p-0 m-0">
    <div class="card-body p-0 m-0">
      <h4 class="card-title ms-2 mt-2">Orderdetailpagina</h4>
      <div class="row p-0 m-0">
        <div class="col-12 p-1 m-0"><h3>{{ order.customer?.name }}{{ getHeader(order.reference) }}{{ getHeader(order.vo_nummer) }}</h3></div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2">
          <div><i class="bi bi-circle-fill close" :class="getColor(order.status?.id)"></i> <b>{{ order.status?.label }}</b></div>
          <div>{{ order.error_message }}</div>
        </div>
        <div class="col-lg-9 col-md-8">
          <div class="orderlines-list">
            <div v-for="(orderline, index) in orderlines" :key="index" class="orderline-item">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Orderregel {{ orderline.orderLineNumber }}</h5>
                  <h6 class="card-subtitle mb-2 text-body-secondary"><b>Aantal:</b> {{ orderline.qtyOrdered.toFixed(0) }} <b>Prijs ex.btw:</b> €{{ Number(orderline.linePrice).toFixed(2) }}</h6>
                  <div class="row custom-row ms-0 me-0">
                    <div class="col-auto">
                      <label for="productCode" class="col-form-label">Product code</label>
                      <EditOrderlineProp id="productCode" :type="'text'" :classStr="'text-end'" :styleStr="'max-width: 12em;'" :rowIndex="index"
                        :orderlineProperty="{value: orderline.productIdentifier?.productCode}" @showToast="(message) => {$emit('showToast', message)}" />
                    </div>
                    <div class="col-auto">
                      <label for="deliveryAddressCode" class="col-form-label">Delivery adres</label>
                      <EditAdresCode id="deliveryAddressCode" :type="'text'" :orderId="order.id" :rowIndex="index"
                        :addresses="orderline.deliveryAddress?.addresses" :addressCode="orderline.deliveryAddress?.id" 
                        @addressCodeChanged="addressCodeChanged" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 ms-2 me-3 px-0">
        <div class="col-lg-3 col-md-4 mt-2">
          <h5>Output Unit4</h5>
        </div>
        <div class="col-lg-9 col-md-8 mb-3 py-2 px-4 " style="border-radius: 7px;">
          <json-tree :data="order.output"></json-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceFactory from "../services/ServiceFactory"
import Utils from "../utils/Utils"
import EditOrderlineProp from "../components/EditOrderlineProp.vue"
import EditAdresCode from '../components/EditAdresCode.vue'
import JsonTree from '../components/JsonTree.vue'

export default {
  name: 'OrderDetailPage',
  props: ['id'],
  components: { EditOrderlineProp, EditAdresCode, JsonTree },
  data() {
    return {
      orderService: {},
      order: {},
      orderlines: [],
      orderproperties: [],
      selectedInvoiceAddress: '',
      treeOptions: {
        maxDepth: 4,
        rootObjectKey: "root",
        modifiable: false,
        link: false,
        limitRenderDepth: false
      }
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status)
    },
    selectInvoiceAddress() {
      if (!this.selectedInvoiceAddress) {
        return
      }
      console.log('selectedInvoiceAddress: ', this.selectedInvoiceAddress)
    },
    async loadOrderDetail() {
      this.orderService = ServiceFactory.getService('OrderService')
      this.order = await this.orderService.getOrder(this.id)
      this.orderlines = this.order.outputb?.lines
    },
    getHeader(txt) {
      if (txt && txt !== '') {
        return " - " + txt
      }
      return ''
    },
    getAddressCode(addressCode) {
      return addressCode ? addressCode : 'onbekend'
    },
    addressCodeChanged(value, index) {
      console.log(`addressCodeChanged: (${value}, ${index})`)
      this.orderlines[index].deliveryAddress.id = value
      this.order.output.lines[index].deliveryAddress.id = value
      this.order.outputb.lines[index].deliveryAddress.id = value
    }
  },
  created() {
    this.loadOrderDetail()
  }
}
</script>

<style scoped>
.orderlines-list {
  display: flex;
  flex-direction: column;
}
.orderline-item {
  padding: 0.5em 0;
}
.custom-row {
  background-color: #f8f9fa; /* Licht grijze achtergrondkleur */
  border-radius: 8px; /* Afgeronde hoeken */
  padding: 1em;
  margin-bottom: 1em;
}
.orderline-item .row {
  margin-bottom: 0.5em;
}
@media (max-width: 767px) {
  .orderline-item .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
