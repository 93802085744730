import api from './axiosConfig';
import CustomEventDispatcher from './CustomEventDispatcher';

class OrderService extends CustomEventDispatcher {

  constructor() {
    super()
    this.response = {};
  }

  async getOrders(page = 1, pageSize = 50) {
    try {
      const response = await api.get(`/api/orders?page=${page}&pageSize=${pageSize}`, {
        credentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const event = new CustomEvent('ordersLoaded', { detail: response.data });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return response.data
    } catch (error) {
      if (error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        console.error('Er is een fout opgetreden in getOrders():', error);
        this.dispatchCustomEvent('getOrdersFailed', error.response.data);
      }
    }
  }

  async getOrder(orderId) {
    try {
      const response = await api.get(`/api/orders/${orderId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const order = response.data

      const event = new CustomEvent('orderLoaded', { detail: order });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return order
    } catch (error) {
      console.error('Er is een fout opgetreden in getOrder():', error);
      if (error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('orderLoadedFailed', error.response.data);
      }
    }
  }

  async retryOrder(order) {
    try {
      const response = await api.put(`/api/orders/retry/${order.id}`, order.output, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const event = new CustomEvent('orderLoaded', { detail: response.data });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return response.data
    } catch (error) {
      console.error('Er is een fout opgetreden in retryOrder():', error);
      if (error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('retryOrderFailed', error.response.data);
      }
    }
  }

  async getOrderstatussen() {
    try {
      const response = await api.get(`/api/orderstatussen`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const orderstatussen = response.data

      const event = new CustomEvent('orderstatussenLoaded', { detail: orderstatussen });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return orderstatussen
    } catch (error) {
      console.error('Er is een fout opgetreden in getOrderStatussen():', error);
      if (error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('getOrderStatussenFailed', error.response.data);
      }
    }
  }

  async updateOrderlineProperty(prop) {
    try {
      const response = await api.post(`/api/order/orderlineprop/update`, prop, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const orderlineprop = response.data

      const event = new CustomEvent('orderlinepropUpdated', { detail: orderlineprop });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return orderlineprop
    } catch (error) {
      console.error('Er is een fout opgetreden in updateOrderlineProperty:', error);
      if (error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('updateOrderlinePropertyFailed', error.response.data);
      }
    }
  }

  async updateAddressCode(orderId, code) {
    try {
      const response = await api.get(`/api/order/addresscode/update/${orderId}/${code}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = response.data

      const event = new CustomEvent('orderAddresscodeUpdated', { detail: data });
      //small timeout required to let data be incorporated in table
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);
      return data
    } catch (error) {
      if (error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        console.error('Er is een fout opgetreden in updateOrderlineProperty:', error);
      }
    }
  }

  normalizeGetOrdersResponse(json) {
    let nJsonOrderResponse = []
    for (let i in json) {
      const order = json[i]
      nJsonOrderResponse.push(this.normalizeOrder(order))
    }
    return nJsonOrderResponse
  }

  normalizeOrder(order) {
    let nOrder = order
    if (order.order_customer) {
      nOrder.customer = order.order_customer.name
    }
    delete order.order_customer
    return order
  }

  getCookie2(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

}

export default OrderService;
