<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="input-wrapper" @click="enableInput" :class="{ 'input-disabled': disabled }">
      <input
        :type="type"
        class="form-control text-center"
        :disabled="disabled"
        @change="changed = true"
        style="max-width: 7em; min-width: 6em;"
        @blur="disableInput"
        @keyup.enter="blurInput"
        ref="inputField"
        v-model="value"
      >
      <button v-if="disabled" class="enable-input-btn"></button>
    </div>

    <select class="form-select"  id="deliveryAddress" v-model="value" @change="updateSelect" >
      <option v-for="(address, index) in addresses" :key="index" :value="address.value" >{{ address.label }}</option>
    </select>
  </div>  
</template>

<script>
import ServiceFactory from '@/services/ServiceFactory';
export default {
  name: 'EditAdresCode',
  props: ['addressCode', 'orderId', 'type', 'addresses', 'rowIndex'],
  emits: ['addressCodeChanged'],
  data() {
    return {
      orderService: {},
      originalValue: '',
      changed: false,
      value: null,
      disabled: true,
    }
  },
  methods:{
    updateAddressCode(event) {
      console.log(event.target)
      let address = this.addresses[event.target.selectedIndex]
      this.value = address.value;
    },
    enableInput() {
      console.log(`enableInput adresCodeInputField-${this.rowIndex}`)
      this.disabled = false;
      this.$nextTick(() => {
        this.$refs.inputField.focus();
      });
    },
    disableInput() {
      this.disabled = true;
      this.saveOrderlineProp()
    },
    updateSelect() {
      this.changed = true
      this.saveOrderlineProp()
    },
    async saveOrderlineProp() {
      if(this.changed) {
        this.$emit('addressCodeChanged', this.value, this.rowIndex)
      }
    },
    blurInput() {
      this.$refs.inputField.blur();
    },
  },
  created() {
    this.value = this.addressCode
    this.originalValue = this.addressCode
    this.orderService = ServiceFactory.getService("OrderService")
  }

}
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

/* transparant button */
.enable-input-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.input-disabled input {
  cursor: pointer;
}

.form-select {
  max-width: 20em;
  margin-left: 1rem;
}
</style>