<template>
  <div :style="{ paddingLeft: `${20}px` }">
    <div v-for="(value, key, index) in data" :key="key" class="tree-item">
      <div v-if="typeof value === 'object'" class="tree-node">
        <div class="tree-line vertical" :style="{ top: getIndex(key, index) === 0 && level === 0 ? '12px' : '0', height: isLastElement(getIndex(key, index)) ? (hasMoreThanOneProp(data) || level > 0 ? '12px' : '0px') : 'auto' }"></div>
        <div class="tree-line horizontal"></div>
        {{ key + (Array.isArray(value) ? ' [ ]' : ' { }') }}:
        <json-tree :data="value" :level="level + 1"></json-tree>
      </div>
      <div v-else class="tree-leaf">
        <div class="tree-line vertical" :style="{ top: getIndex(key, index) === 0 && level === 0 ? '12px' : '0', height: isLastElement(getIndex(key, index)) ? '12px' : 'auto' }"></div>
        <div class="tree-line horizontal"></div>
        {{ key }}: {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonTree',
  props: {
    data: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getIndex(key, index) {
      return Array.isArray(this.data) ? key : index;
    },
    hasMoreThanOneProp(value) {
      return Object.keys(value).length > 1;
    },
    isLastElement(index) {
      return Array.isArray(this.data) ? index === this.data.length - 1 : index === Object.keys(this.data).length - 1;
    }
  }
}
</script>

<style scoped>
.tree-item {
  position: relative;
}

.tree-line {
  position: absolute;
  border-left: 1px solid #000;
}

.tree-line.vertical {
  top: 0;
  bottom: 0;
  left: -10px;
}

.tree-line.horizontal {
  top: 12px;
  left: -10px;
  right: 100%;
  border-left: none;
  border-top: 1px solid #000;
}
</style>