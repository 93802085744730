import axios from 'axios';
import localDB from '../utils/localDB';

const rootUrl = () => {
  if (window.location.origin.indexOf('localhost') === -1) {
    return '';
  } else {
    return "http://localhost:3000";
  }
}

const api = axios.create({
  baseURL: rootUrl(), // Gebruik de juiste base URL
});

// Request interceptor
api.interceptors.request.use(async (config) => {
  const token = await localDB.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
